import React, { FC, useEffect, useRef, useState } from 'react';
import { css } from 'utils/helper';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import UserDropdown from './UserDropdown';
import AdminLinkPanel from './AdminLinkPanel';
import GroupsLinkPanel from './GroupsLinkPanel';
import routes, { Route } from './routes';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import useWindowSize from 'utils/hooks/useWindowSize';
import Image from 'next/image';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';

interface IHeader {
  isHeaderTempFix: boolean;
}

const Header: FC<IHeader> = ({ isHeaderTempFix }): React.ReactElement => {
  const [isUserPopupActive, setIsUserPopupActive] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<number | null>(null);
  const header = useRef<HTMLInputElement>(null);
  const userDropElement = useRef<HTMLUListElement>(null);
  const [navigationLinks, setNavigationLinks] = useState<Route[]>(routes);
  const { logged } = useSelector((state: RootState) => state.userData);
  const [{ user }] = useCookies(['user']);
  const { width } = useWindowSize();
  const isMobile = width !== 0 && width <= 767;
  const router = useRouter();

  useEffect(() => {
    setIsUserPopupActive(false);
  }, [router.pathname]);

  const changeSelectedItem = (index: number | null) => {
    setSelectedItem(index);
  };

  useEffect(() => {
    css(document.body, { overflow: isMobile ? 'hidden' : 'visible' });
    if (document.body.offsetWidth <= 768) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [isMobile]);

  useOnClickOutside(header, () => {
    setIsUserPopupActive(false);
  });

  const navigationElements = navigationLinks.map((item, index) => {
    if (item.group || item.admin) {
      if ((!logged && item.label === 'svg') || (item.admin && !user?.isAdmin)) return null;

      return (
        <li
          className={`header__nav-item${globalThis.window?.location.pathname === item.link ? ' isActive' : ''}`}
          key={`${item.label}_${index}`}
        >
          {!item.admin ? (
            <GroupsLinkPanel
              changeSelectedItem={changeSelectedItem}
              index={index}
              label={item.label}
              isMobile={isMobile}
              isActive={index === selectedItem}
              groupLink={item.group}
              link={item.link}
              setIsUserPopupActive={() => setIsUserPopupActive(false)}
            />
          ) : (
            <AdminLinkPanel
              index={index}
              isActive={index === selectedItem}
              changeSelectedItem={changeSelectedItem}
              isMobile={isMobile}
            />
          )}
        </li>
      );
    }

    return (
      <li
        className={`header__nav-item${router.pathname === item.link ? ' isActive' : ''}`}
        key={`${item.label}_${index}`}
        onClick={() => setIsUserPopupActive(false)}
      >
        <Link href={item.link}>{item.label}</Link>
      </li>
    );
  });

  const onClickUserPopupHandler = (): void => {
    setIsUserPopupActive((prevState) => !prevState);
  };

  return (
    <header className={`header ${isHeaderTempFix ? 'header-temp-fix' : ''}`} ref={header}>
      <div className="header__body container">
        <Link className="header__logo" href="/">
          <Image width={118} height={23} src="/images/global/logo-dfa-light.svg" alt="Digital Freight Alliance" />
        </Link>

        <nav className={`header__nav${isMobile && isUserPopupActive ? ' isOpen' : ''}`}>
          <ul className="header__nav-list">{navigationElements}</ul>
          <ul className="header__nav-list" ref={userDropElement}>
            {logged ? (
              <>
                <li className={`header__nav-item header__nav-item-right`} onClick={onClickUserPopupHandler}>
                  <span className={`header__nav__user-icon ${isUserPopupActive ? 'active' : ''}`} />
                </li>
                <UserDropdown isUserPopupActive={isUserPopupActive} isMobile={isMobile} />
              </>
            ) : (
              <li className={`header__nav-item${router.pathname === '/auth/sign-in' ? ' mobileActiveLink' : ''}`}>
                <Link href="/auth/sign-in">Sign In</Link>
              </li>
            )}
          </ul>
        </nav>

        {isMobile && (
          <div className="burger__wrapper" onClick={() => setIsUserPopupActive(!isUserPopupActive)}>
            <div className={`burger${isUserPopupActive ? ' isOpen' : ''}`} />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
