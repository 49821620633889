import { apiMap } from 'api/apiMap';
import axios from 'axios';

const logoutHandler = async (remove: any) => {
  const { data: response } = await axios.post(apiMap.logout);
  if (response.data.status === 'ok') {
    remove('user', { path: '/' });
    location.href = location.pathname === '/directory' ? '/auth/sign-in' : '/';
  } else {
    alert('Logout failed');
    console.log('Logout - response: ', response);
  }
};

export default logoutHandler;
