import React from 'react';
import { Route } from 'components/Header/routes';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface IGroupsLinkPanel {
  groupLink: Route[];
  label: string;
  index: number;
  isMobile: boolean;
  isActive: boolean;
  link: string;
  changeSelectedItem(argument: number | null): void;
  setIsUserPopupActive: () => void;
}

const GroupsLinkPanel = ({
  groupLink,
  label,
  index,
  isActive,
  isMobile,
  link,
  changeSelectedItem,
  setIsUserPopupActive,
}: IGroupsLinkPanel): React.ReactElement => {
  const router = useRouter();

  const clickItemHandler = (argument: number) => {
    if (isActive) {
      changeSelectedItem(null);
      return;
    }

    changeSelectedItem(argument);
  };

  return (
    <>
      {!isMobile ? (
        <div className="header__nav-item--sublink">
          <Link href={link} className="header__nav-item-label-box">
            {label}
          </Link>
          <ul className={`header__sublink`}>
            {groupLink.map(({ label, link }, index) => {
              return (
                <React.Fragment key={`${label}_${index}`}>
                  <Link className="header__sublink-link" href={link}>
                    {label}
                  </Link>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      ) : (
        <>
          <div
            className={`header__nav-item--sublink ${router.pathname === link && 'mobileActiveLink'} ${
              isActive && 'active'
            }`}
          >
            <Link
              href={link}
              onClick={() => {
                setIsUserPopupActive();
                clickItemHandler(index);
              }}
            >
              {label}
            </Link>
            <div className="mobile-arrow-wrapper" onClick={() => clickItemHandler(index)}>
              <div className="mobile-arrow"></div>
            </div>
          </div>

          <div className={`header__sublink-box ${!isActive || 'active'}`}>
            {groupLink.map(({ label, link }, index) => {
              return (
                <React.Fragment key={`${label}_${index}`}>
                  <Link
                    className={`header__sublink-link ${router.pathname === link && 'mobileActiveLink'}`}
                    href={link}
                    onClick={setIsUserPopupActive}
                  >
                    {label}
                  </Link>
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default GroupsLinkPanel;
