import React, { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import logoutHandler from 'utils/logoutHandler';

interface IUserDropdownProps {
  isUserPopupActive: boolean;
  isMobile: boolean;
}

interface IHasSrc extends HTMLImageElement {
  src: string;
}

interface IUserData {
  company: string;
  first_name: string;
  logo: string;
  validity: string;
  isAdmin: boolean;
  type: string;
}

const UserDropdown: FC<IUserDropdownProps> = ({ isUserPopupActive, isMobile }): React.ReactElement => {
  const [{ user }, _, remove] = useCookies(['user']);
  const [userData, setUserData] = useState<IUserData>({
    company: 'Unknown company',
    first_name: 'Unknown user',
    logo: '/images/directory/user_default-logo.svg',
    validity: new Date().toString(),
    isAdmin: false,
    type: 'REQUEST',
  });

  useEffect(() => {
    if (!user) return;
    const { company, first_name, logo, validity, isAdmin, type } = user;
    setUserData(() => ({
      company,
      first_name,
      logo,
      validity,
      isAdmin,
      type,
    }));
  }, [user]);

  const userProfileStatus = userData.isAdmin ? 'admin' : 'member';
  const validityDateOptions = { year: 'numeric', month: 'short', day: 'numeric' } as const;
  const validityDate = new Intl.DateTimeFormat('en-US', validityDateOptions).format(new Date(userData.validity));

  const logoImgErrorHandler = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const target = e.target as IHasSrc;
    if (target) {
      target.src = '/images/directory/user_default-logo.svg';
      console.clear();
    }
  };

  return (
    <div className={`user-dropdown ${isUserPopupActive ? 'active' : ''} ${isMobile ? 'mobOpen' : ''}`}>
      <div className="user-dropdown__header">
        <img
          className="header-icon"
          alt="DFA user icon"
          src={userData.logo ?? '/images/directory/user_default-logo.svg'}
          onError={logoImgErrorHandler}
        />
        <div className="header-context">
          <h4 className="header-context__username">Hi {userData.first_name || 'Unknown user'}!</h4>
          <p className="header-context__company">{userData.company || 'Support'}</p>
        </div>
      </div>
      <div className="user-dropdown__context">
        {!userData.isAdmin && (
          <a className="profile-line profile-status" href="/directory/virtual-office">
            Profile
            <span className={userProfileStatus}>{userProfileStatus}</span>
          </a>
        )}
        <button className="signout-btn" onClick={() => logoutHandler(remove)}>
          Sign out
        </button>
      </div>
      {userData.type === 'PREMIUM' ? (
        <div className="user-dropdown__footer">
          <p className="expires-status">
            DFA Member Expires <span>{userData.validity ? validityDate.toString() : 'no data'}</span>
          </p>
          <a className="expires-btn" href="/membership">
            Renew membership
          </a>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserDropdown;
